import { useRef } from 'react';
import { useScroll, useTransform } from 'framer-motion';

export const useScrollVariables = () => {
  const labelContainerRef = useRef(null);
  const projectTextRef = useRef(null);
  const { scrollYProgress: labelScrollYProgress } = useScroll({
    target: labelContainerRef,
    initial: 0,
    throttle: 100,
  });
  const { scrollYProgress: projectTextScrollYProgress } = useScroll({
    target: projectTextRef,
    offset: ['1 1', '0 0']
  });
  const labelOpacity = useTransform(labelScrollYProgress, [0, 0.01], [1, 0]);
  const projectTextOpacity = useTransform(projectTextScrollYProgress, [0.5, 1], [1, 0]);

  return {
    labelContainerRef,
    projectTextRef,
    labelOpacity,
    projectTextOpacity,
  };
};