import { Fade } from "react-awesome-reveal";
import { motion } from 'framer-motion';
import { useScrollVariables } from '../scrollVariables';

export default function Slipstream() {

        const {
                labelContainerRef,
                projectTextRef,
                labelOpacity,
                projectTextOpacity,
        } = useScrollVariables();

        return (
                <div className="slipstream" id="slipstream">
                        <div className="label-container" ref={labelContainerRef}>
                                <motion.div style={{ opacity: labelOpacity }}>
                                        02 SLIPSTREAM OF YOUR MIND
                                </motion.div>
                        </div>
                        <div className="container info-section justify-content-center">
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>CLIENT</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>SLIPSTREAM OF YOUR MIND</h3>
                                        </div>
                                </div>
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>ROLE</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>FREELANCE GRAPHIC DESIGN</h3>
                                        </div>
                                </div>
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>DELIVERABLES</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>LOGO DESIGN</h3>
                                                <h3>BRAND IDENTITY</h3>
                                                <h3>VISUAL IDENTITY</h3>
                                                <h3>PHOTOGRAPHY</h3>
                                        </div>
                                </div>
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>YEAR</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>2023/2024</h3>
                                        </div>
                                </div>
                        </div>
                        <div className="d-flex justify-content-center">
                                <motion.p className="project-text" ref={projectTextRef} style={{ opacity: projectTextOpacity }}>Slipstream of Your Mind is a band located in Greater York Region who creates original music that combines American Folk, 70's Country, Early Rock N' Roll, modern Indie, and 60's psychadelia. With this in mind, the goal was to design assets that are reminiscent of these traditional genres and infuse them with a contemporary sensibility. A full visual identity package was created for the client, that including promotional poster designs, photography, and a logo.</motion.p>
                        </div>
                        <div className="container work-container">
                                <div className="row align-items-center justify-content-center" id="gallery">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-logo.png" className="slipstream-work-img-logo" alt="slipstream-logo" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-logo-word-mark.png" className="slipstream-work-img-logo" alt="slipstream-logo-alt" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-mockup-1.png" className="slipstream-work-mockup" alt="slipstream-mockup-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-mockup-2.png" className="slipstream-work-mockup" alt="slipstream-mockup-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-mockup-vinyl.png" className="slipstream-work-mockup" alt="slipstream-mockup-3" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xs-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-poster-1.jpg" className="slipstream-work-img-group" alt="slipstream-poster-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xs-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-logo-poster-2.jpg" className="slipstream-work-img-group" alt="slipstream-logo-poster-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-lg-4 col-xs-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-poster-2.jpg" className="slipstream-work-img-group" alt="slipstream-poster-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-6 col-xs-3 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-poster-3.jpg" className="slipstream-work-img-group" alt="slipstream-poster-3" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-6 col-xs-3 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-poster-4.jpg" className="slipstream-work-img-group" alt="slipstream-poster-4" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-lg-8 col-xs-12 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-poster-6.jpg" className="slipstream-work-img-group" alt="slipstream-poster-6" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-6 col-xs-3 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-logo-poster-1.jpg" className="slipstream-work-img-group" alt="slipstream-logo-poster-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-6 col-xs-3 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-poster-5.jpg" className="slipstream-work-img-group" alt="slipstream-poster-5" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-1.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-2.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-3.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-3" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-4.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-4" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-5.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-5" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-6.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-6" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-7.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-7" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-bw-img-8.jpg" className="slipstream-work-img-photos" alt="slipstream-bw-img-8" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-1.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade>
                                                        <img src="img/slipstream-work/slipstream-col-img-2.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-3.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-3" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-4.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-4" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-5.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-5" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-6.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-6" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-7.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-7" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/slipstream-work/slipstream-col-img-8.jpg" className="slipstream-work-img-photos" alt="slipstream-col-img-8" />
                                                </Fade>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}