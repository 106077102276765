import { Fade } from "react-awesome-reveal";
import { motion } from 'framer-motion';
import { useScrollVariables } from '../scrollVariables';

export default function AGO() {

        const {
                labelContainerRef,
                projectTextRef,
                labelOpacity,
                projectTextOpacity,
        } = useScrollVariables();

        return (
                <div className="ago" id="ago">
                        <div className="label-container" ref={labelContainerRef}>
                                <motion.div style={{ opacity: labelOpacity }}>
                                        04 ART GALLERY OF ONTARIO
                                </motion.div>
                        </div>
                        <div className="ago-work-container-full">
                                <div className="container info-section justify-content-center">
                                        <div className="grid-even-columns">
                                                <div className="description-title">
                                                        <h3>COMPANY</h3>
                                                </div>
                                                <div className="description-text">
                                                        <h3>ART GALLERY OF ONTARIO</h3>
                                                </div>
                                        </div>
                                        <div className="grid-even-columns">
                                                <div className="description-title">
                                                        <h3>ROLE</h3>
                                                </div>
                                                <div className="description-text">
                                                        <h3>GRAPHIC DESIGN INTERN</h3>
                                                </div>
                                        </div>
                                        <div className="grid-even-columns">
                                                <div className="description-title">
                                                        <h3>DELIVERABLES</h3>
                                                </div>
                                                <div className="description-text">
                                                        <h3>EXHIBITION POSTER</h3>
                                                        <h3>EXHIBITION POSTCARD</h3>
                                                        <h3>EDITORIAL DESIGN</h3>
                                                </div>
                                        </div>
                                        <div className="grid-even-columns">
                                                <div className="description-title">
                                                        <h3>YEAR</h3>
                                                </div>
                                                <div className="description-text">
                                                        <h3>2018</h3>
                                                </div>
                                        </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                        <motion.p className="project-text" ref={projectTextRef} style={{ opacity: projectTextOpacity }}>As a Graphic Design Intern at the Art Gallery of Ontario, I worked on various projects that included an exhibition poster, an exhibition post card, and an editorial advertisement for the Walrus Magazine. Throughout the internship I learned how to collaborate with multiple departments including upper management, marketing, and the design department, as well as how to include corporate sponsors into a graphic layout. It was an honour to combine Design with my passion for the Art World and create designs that will always be dear to me.</motion.p>
                                </div>
                                <div className="container work-container">
                                        <div className="row align-items-center justify-content-center" id="gallery">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                        <Fade duration={500}>
                                                                <img src="img/ago-work/ago1.jpg" className="ago-work-img" alt="ago-1" />
                                                        </Fade>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                        <Fade duration={500}>
                                                                <img src="img/ago-work/ago-poster-mockup.jpg" className="ago-work-img-mockup" alt="ago-mockup-1" />
                                                        </Fade>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                        <Fade duration={500}>
                                                                <img src="img/ago-work/ago2.jpg" className="ago-work-img" alt="ago-2" />
                                                        </Fade>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                        <Fade duration={500}>
                                                                <img src="img/ago-work/ago-postcard-mockup.jpg" className="ago-work-img-mockup" alt="ago-mockup-2" />
                                                        </Fade>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                        <Fade duration={500}>
                                                                <img src="img/ago-work/ago3.jpg" className="ago-work-img" alt="ago-3" />
                                                        </Fade>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                                                        <Fade duration={500}>
                                                                <img src="img/ago-work/ago-magazine-mockup.jpg" className="ago-work-img-mockup" alt="ago-mockup-3" />
                                                        </Fade>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}