import React, { useRef } from 'react';
import { motion, useScroll } from "framer-motion"

export default function Header() {

    const elementRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: elementRef,
        offset: ['0 0', '0 0.5']
    })

    return (
        <div className="header">
                <motion.div className="header-container text-center" 
                    initial={{ opacity: 0 }} 
                     animate={{ opacity: 1 }} 
                     transition={{ delay: 0.8, duration: 1.5 }} >
                    <img src="img/landing-page-img.jpg" className="landing-img" alt="ago1" />
                </motion.div>
            <div className="header-title text-center">
                <motion.h1 
                    className="mikel-header" 
                    ref={elementRef} 
                    style={{ opacity: scrollYProgress }} 
                    initial={{ opacity: 0 }} 
                    animate={{ opacity: 1 }} 
                    transition={{ delay: 0.5, duration: 0.5 }} >MIKEL REED BALAZIC
                </motion.h1>
            </div>
        </div>
    )
}
