import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from "framer-motion"

export default function About() {

    const elementRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: elementRef,
        offset: ['1 1', '0 0']
    })
    const scale1 = useTransform(scrollYProgress, [0, 0.8], [1, 0]);
    const scale2 = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
    const opacity1 = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
    const opacity2 = useTransform(scrollYProgress, [0, 0.9], [0, 1]);

    return (
        <div className="about" id="about">
            <div className="about-container">
                <div className="about-text-container d-flex justify-content-center">
                    <p className="about-text">My name is Mikel Reed Balazic, I am a graphic designer based in the Greater Toronto Area. My fundamental interest is finding a creative solution to a problem with efficiency and technical standard.</p>
                </div>
                <div className="container d-flex justify-content-center">
                    <motion.img src="img/logo.png" className="logo-img" ref={elementRef} style={{ scale: scale1, opacity: opacity1 }} alt="logo" />
                </div>
                <div className="work-header-container container d-flex justify-content-center align-items-center">
                    <motion.h1 className="work-header" ref={elementRef} style={{ scale: scale2, opacity: opacity2 }}>WORK</motion.h1>
                </div>
            </div>
        </div>
    )
}