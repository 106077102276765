import { useState } from "react";
import { Fade } from "react-awesome-reveal";

export default function Nav() {
    
    const [navOpen, setNavOpen] = useState(false);
    const handleClick = () => {
        setNavOpen(!navOpen);
    };

    return (
        <div className="nav">
            <div className="nav-container">
                <div className="menu-toggle" onClick={handleClick}>
                    <div className="nav-menu">
                        <Fade delay={500}>
                            <span className={`nav-menu-label ${navOpen ? "click" : ""}`}></span>
                        </Fade>
                    </div>
                </div>
                <div className="nav-overlay" style={{ top: navOpen ? "0" : "-102%" }}>
                    <Fade cascade damping={0.3}>
                        <ul className="nav-links">
                            {["about", "sfmusic", "slipstream", "hhth", "ago"].map((item) => (
                                <li className="nav-item" key={item}>
                                    <a href={`#${item}`} onClick={handleClick}>
                                        {item.toUpperCase()}
                                    </a>
                                    <div className="nav-itemwrapper"></div>
                                </li>
                            ))}
                        </ul>
                    </Fade>
                </div>
            </div>
        </div>
    );
}