import { Fade } from "react-awesome-reveal";
import { motion } from 'framer-motion';
import { useScrollVariables } from '../scrollVariables';

export default function Sfmusic() {

    const {
        labelContainerRef,
        projectTextRef,
        labelOpacity,
        projectTextOpacity,
    } = useScrollVariables();

    return (
        <div className="sfmusic" id="sfmusic">
            <div className="label-container" ref={labelContainerRef}>
                <motion.div style={{ opacity: labelOpacity }}>
                    01 STEVEN FUSCO MUSIC
                </motion.div>
            </div>
            <div className="container info-section justify-content-center">
                <div className="grid-even-columns">
                    <div className="description-title">
                        <h3>CLIENT</h3>
                    </div>
                    <div className="description-text">
                        <h3>STEVEN FUSCO MUSIC</h3>
                    </div>
                </div>
                <div className="grid-even-columns">
                    <div className="description-title">
                        <h3>ROLE</h3>
                    </div>
                    <div className="description-text">
                        <h3>FREELANCE GRAPHIC DESIGN</h3>
                    </div>
                </div>
                <div className="grid-even-columns">
                    <div className="description-title">
                        <h3>DELIVERABLES</h3>
                    </div>
                    <div className="description-text">
                        <h3>LOGO DESIGN</h3>
                        <h3>BRAND IDENTITY</h3>
                        <h3>VISUAL IDENTITY</h3>
                        <h3>PHOTOGRAPHY</h3>
                        <h3>MOTION DESIGN</h3>
                    </div>
                </div>
                <div className="grid-even-columns">
                    <div className="description-title">
                        <h3>YEAR</h3>
                    </div>
                    <div className="description-text">
                        <h3>2023/2024</h3>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                    <motion.p className="project-text" ref={projectTextRef} style={{ opacity: projectTextOpacity }}>Steven Fusco is a solo artist located in the Greater Toronto area who creates original music with an emphasis on themes of overcoming, determination, and freedom. A full visual identity package was created for the client, that including promotional poster designs, photography, motion design, and a logo. These items lay a core foundation for fans to experience the music from an added dimension, and fulfill their own meaning as a listener. </motion.p>
            </div>
            <div className="container work-container">
                <div className="row align-items-center justify-content-center" id="gallery">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-logo.png" className="fusco-work-img-logo" alt="fusco-logo" />
                        </Fade>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-logo-word-mark.png" className="fusco-work-img-logo" alt="fusco-logo-alt" />
                        </Fade>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-mockup-1.png" className="fusco-work-img-mockup" alt="fusco-mockup-1" />
                        </Fade>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-mockup-2.png" className="fusco-work-img-mockup" alt="fusco-mockup-2" />
                        </Fade>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-mockup-3.png" className="fusco-work-img-mockup" alt="fusco-mockup-3" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 col-xs-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-1.jpg" className="fusco-work-img-group" alt="fusco-poster-1" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 col-xs-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-2.jpg" className="fusco-work-img-group" alt="fusco-poster-2" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 col-xs-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-3.jpg" className="fusco-work-img-group" alt="fusco-poster-3" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-6 col-xs-3 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-logo-poster-2.jpg" className="fusco-work-img-group" alt="fusco-logo-poster-2" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-6 col-xs-3 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-4.jpg" className="fusco-work-img-group" alt="fusco-poster-4" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-8 col-xs-12 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-moonglow.jpg" className="fusco-work-img-group" alt="fusco-poster-moonglow" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-6 col-xs-3 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-animation-1.gif" className="fusco-work-img-group" alt="fusco-animation-1" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-6 col-xs-3 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-animation-2.gif" className="fusco-work-img-group" alt="fusco-animation-2" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 col-xs-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-7.jpg" className="fusco-work-img-group" alt="fusco-poster-8" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 col-xs-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-logo-poster-1.jpg" className="fusco-work-img-group" alt="fusco-logo-poster-1" />
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 col-xs-4 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-poster-8.jpg" className="fusco-work-img-group" alt="fusco-poster-9" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-bw-img-1.jpg" className="fusco-work-img-photos" alt="fusco-bw-img-1" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-bw-img-2.jpg" className="fusco-work-img-photos" alt="fusco-bw-img-2" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-bw-img-3.jpg" className="fusco-work-img-photos" alt="fusco-bw-img-3" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-bw-img-4.jpg" className="fusco-work-img-photos" alt="fusco-bw-img-4" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-col-img-1.jpg" className="fusco-work-img-photos" alt="fusco-col-img-1" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-col-img-2.jpg" className="fusco-work-img-photos" alt="fusco-col-img-2" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-col-img-3.jpg" className="fusco-work-img-photos" alt="fusco-col-img-3" />
                        </Fade>
                    </div>
                    <div className="col-6 col-lg-3 col-xs-2 text-center">
                        <Fade duration={500}>
                            <img src="img/fusco-work/fusco-col-img-4.jpg" className="fusco-work-img-photos" alt="fusco-col-img-4" />
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
}