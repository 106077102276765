import React, { useState, useRef, useEffect } from "react";
import { validateEmail } from "../../utils/helpers";
import { Fade } from "react-awesome-reveal";
import emailjs from 'emailjs-com';

function Contact() {
    
    const [contactOpen, setContactOpen] = useState(false);
    const [formState, setFormState] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [errorMessage, setErrorMessage] = useState("");
    const form = useRef();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };
    const handleBlank = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";

        if (value.trim() === "" && (name === "name" || name === "message")) {
            errorMessage = `${name.toUpperCase()} IS REQUIRED.`;
        } else if (name === "email") {
            const isValid = validateEmail(value);
            errorMessage = isValid ? "" : "YOUR EMAIL IS INVALID.";
        }
        setErrorMessage(errorMessage);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formElement = document.getElementById('contact-form');
        emailjs
            .sendForm('service_23xjuf7', 'template_egh1mle', formElement, '7Pp3J38zi_F5L3HZz')
            .then(
                () => {
                    console.log('Email sent successfully');
                    setContactOpen(false);
                },
                (error) => {
                    console.error('Failed to send email:', error);
                }
            );
        setFormState({
            name: "",
            email: "",
            message: "",
        });
    };
    const handleClick = () => {
        setContactOpen(!contactOpen);
    };
    useEffect(() => {
        if (!contactOpen) {
            setErrorMessage("");
        }
    }, [contactOpen]);

    return (
        <div className="contact" style={{ zIndex: contactOpen ? 102 : 100 }}>
            <div className="contact-container">
                <div className="menu-toggle" onClick={handleClick}>
                    <div className="contact-menu">
                        <Fade delay={500}>
                            <span className={`contact-menu-label ${contactOpen ? "click" : ""}`}></span>
                        </Fade>
                    </div>
                </div>
                <div className="contact-overlay" style={{ bottom: contactOpen ? "0" : "-102%" }}>
                    <Fade triggerOnce={true}>
                        <form className="contact-form-styles" id="contact-form" ref={form} onSubmit={handleSubmit}>
                            <div className="input-div">
                                <label htmlFor="name">NAME:</label>
                                <br />
                                <input
                                    type="text"
                                    value={formState.name}
                                    onBlur={handleBlank}
                                    id="name"
                                    name="name"
                                    onChange={handleChange}
                                    autoComplete="name"
                                />
                            </div>
                            <div className="input-div">
                                <label htmlFor="email">EMAIL ADDRESS:</label>
                                <br />
                                <input
                                    type="email"
                                    value={formState.email}
                                    id="email"
                                    name="email"
                                    onBlur={handleBlank}
                                    onChange={handleChange}
                                    autoComplete="email"
                                />
                            </div>
                            <div className="input-div">
                                <label htmlFor="message">MESSAGE:</label>
                                <br />
                                <input
                                    type="text"
                                    value={formState.message}
                                    id="message"
                                    name="message"
                                    onBlur={handleBlank}
                                    rows="5"
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="button-error-container">
                                <div className="button-container">
                                    <button type="submit">SEND</button>
                                </div>
                                {errorMessage && (
                                    <p className="error-text">{errorMessage}</p>
                                )}
                            </div>
                            <div className="social-icon-container">
                                <a href="https://www.instagram.com/mikelreedbalazic/" className="container" target="_blank" rel="noreferrer">
                                    <img src="img/instagram-logo.png" className="instagram-logo" alt="instagram-logo" />
                                </a>
                                <a href="https://www.linkedin.com/in/mikel-balazic/" className="container" target="_blank" rel="noreferrer">
                                    <img src="img/linkedin-logo.png" className="linkedin-logo" alt="linkedin-logo" />
                                </a>
                            </div>
                        </form>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default Contact;