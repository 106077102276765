import { Fade } from "react-awesome-reveal";
import { motion } from 'framer-motion';
import { useScrollVariables } from '../scrollVariables';

export default function HHTH() {

        const {
                labelContainerRef,
                projectTextRef,
                labelOpacity,
                projectTextOpacity,
        } = useScrollVariables();

        return (
                <div className="hhth" id="hhth">
                        <div className="label-container" ref={labelContainerRef}>
                                <motion.div style={{ opacity: labelOpacity }}>
                                        03 HOCKEY HELPS THE HOMELESS
                                </motion.div>
                        </div>
                        <div className="container info-section justify-content-center">
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>COMPANY</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>HOCKEY HELPS THE HOMELESS</h3>
                                        </div>
                                </div>
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>ROLE</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>GRAPHIC DESIGNER</h3>
                                        </div>
                                </div>
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>DELIVERABLES</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>LOGO DESIGN</h3>
                                                <h3>VISUAL IDENTITY</h3>
                                                <h3>SOCIAL MEDIA CONTENT</h3>
                                        </div>
                                </div>
                                <div className="grid-even-columns">
                                        <div className="description-title">
                                                <h3>YEAR</h3>
                                        </div>
                                        <div className="description-text">
                                                <h3>2023</h3>
                                        </div>
                                </div>
                        </div>
                        <div className="d-flex justify-content-center">
                                <motion.p className="project-text" ref={projectTextRef} style={{ opacity: projectTextOpacity }}>At Hockey Helps The Homeless, I had the opporunity to collaborate on a number of projects that include social media posts, player cards for the HHTH website, a Hockey Helps The Homeless alternative Waterloo logo, and a billboard that was displayed in the annual Calgary tournament. It was a great experience to be able to expand my design knowledge within the sports design world.</motion.p>
                        </div>
                        <div className="container work-container">
                                <div className="row align-items-center justify-content-center" id="gallery">
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-waterloo-logo.png" className="hhth-work-img" alt="hhth-waterloo-logo" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-4 col-sm-4 col-xs-3 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-social-1.jpg" className="hhth-work-img" alt="hhth-social-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-4 col-sm-4 col-xs-3 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-social-2.jpg" className="hhth-work-img" alt="hhth-social-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-social-mockup.jpg" className="hhth-work-img" alt="hhth-social-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-billboard.jpg" className="hhth-work-img-board" alt="hhth-billboard" />
                                                </Fade>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-billboard-mockup.jpg" className="hhth-work-img-board" alt="hhth-billboard" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-1.jpg" className="hhth-work-img-photos" alt="hhth-card-1" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-2.jpg" className="hhth-work-img-photos" alt="hhth-card-2" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-3.jpg" className="hhth-work-img-photos" alt="hhth-card-3" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-4.jpg" className="hhth-work-img-photos" alt="hhth-card-4" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-5.jpg" className="hhth-work-img-photos" alt="hhth-card-5" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-6.jpg" className="hhth-work-img-photos" alt="thhth-card-6" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-7.jpg" className="hhth-work-img-photos" alt="hhth-card-7" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-8.jpg" className="hhth-work-img-photos" alt="hhth-card-8" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-9.jpg" className="hhth-work-img-photos" alt="hhth-card-9" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-10.jpg" className="hhth-work-img-photos" alt="hhth-card-10" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-11.jpg" className="hhth-work-img-photos" alt="hhth-card-11" />
                                                </Fade>
                                        </div>
                                        <div className="col-6 col-lg-3 col-xs-2 text-center">
                                                <Fade duration={500}>
                                                        <img src="img/hhth-work/hhth-card-12.jpg" className="hhth-work-img-photos" alt="hhth-card-12" />
                                                </Fade>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}