import { Fade } from "react-awesome-reveal";

const Loader = () => {
    return (
        <div className="loader-delay">
            <Fade>
                <div className="loader">
                    <Fade>
                        <div className="transparent-logo-div">
                            <img src="img/logo.gif" className="transparent-logo" alt="transparent-logo" loading="lazy" />
                        </div>
                    </Fade>
                </div>
            </Fade>
        </div>
    )
}

export default Loader