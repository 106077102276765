import Loader from "./components/loader/Loader";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Sfmusic from "./components/sfmusic/Sfmusic";
import Slipstream from "./components/slipstream/Slipstream";
import HHTH from "./components/hhth/HHTH";
import AGO from "./components/ago/AGO";
import Contact from "./components/contact/Contact";
import "./app.css"
import { useState, useEffect } from "react";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 5500);
    }
    fakeDataFetch();
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className="app">
      <Header />
      <div className="nav-parent">
        <Nav />
      </div>
      <div className="sections">
        <About />
        <Sfmusic />
        <Slipstream />
        <HHTH />
        <AGO />
        <Contact />
      </div>
      <img src="img/landing-page-img.jpg" className="landing-img-mobile" alt="ago1" />
    </div>
  );
}

export default App;